import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export default function Header({ name, handleLogout }) {
    return (
        <Wrapper>
            {name ? (
                <UserArea>{/*<Logout onClick={handleLogout}>Logout</Logout>*/}</UserArea>
            ) : (
                <></>
            )}
        </Wrapper>
    );
}

Header.propTypes = {
    name: PropTypes.string,
};

const Wrapper = styled.div`
    padding: 10px;
`;

const UserArea = styled.div`
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: 12px;
    position: relative;
    top: 9px;
`;

// const Logout = styled.button`
//     border-radius: 40px;
//     border: 1px solid #d1def0;
//     background-color: #d1def0;
//     color: var(--sydney);
//     font-size: 12px;
//     margin-bottom: 5px;
//     outline: none;
//     padding: 6px 15px;
//     transition: 0.4s all;
//     &:hover {
//         cursor: pointer;
//         border: 1px solid #222;
//     }
// `;
