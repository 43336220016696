import React from 'react';
import { Link } from 'react-router-dom';

import { HouseWrapper, Address, House, TextWrapper, Owner, Phone, Email } from '../DefaultStyles';
import Arrow from '../components/Back';

export default function Home_116Palmcroft() {
    return (
        <HouseWrapper>
            <House src="/images/116palmcroft.jpg" alt="xxxx" />
            <TextWrapper>
                <Address>116 Palmcroft Dr.</Address>
                <Owner>Hal & Carolyn Jenkins</Owner>
                <Phone href="tel:480-966-4971">480-966-4971 </Phone>
                <Email href="mailto:hjenkins3@cox.net">hjenkins3@cox.net</Email>
                <ul>
                    Notes
                    <li>Phone is a landine.</li>
                </ul>
            </TextWrapper>
            <Link to="/map">
                <Arrow />
            </Link>
        </HouseWrapper>
    );
}
