export const users = [
    {
        id: 0,
        username: 'Penny & Bill',
        login: 'pennybill',
        password: '!PalM_133_cr0ft!',
    },
    {
        id: 1,
        username: 'Lisa & John',
        login: 'lisajohn',
        password: '!Palm_108_cr0ft!',
    },
    {
        id: 2,
        username: 'Daphne & Dave',
        login: 'daphnedave',
        password: '!Palm_36_dAlE!',
    },
    {
        id: 3,
        username: 'Carolyn & Hal',
        login: 'carolynhal',
        password: '##paLm_116_CRoFT##',
    },
    {
        id: 4,
        username: 'Manjula',
        login: 'manjula',
        password: '!PalM_120_Cr0ft!',
    },
    {
        id: 5,
        username: 'Deborah & Neil',
        login: 'deborahneil',
        password: '!Dr0me_2015_Dary!',
    },
    {
        id: 6,
        username: 'Janeen',
        login: 'janeen',
        password: '!Palm_114_Dale!',
    },
    {
        id: 7,
        username: 'Sarah & Larry',
        login: 'sarahlarry',
        password: '!Palm_103_Cr0ft!',
    },
    {
        id: 8,
        username: 'Debbie & Kris',
        login: 'debbiekris',
        password: '!Palm_25_Cr0ft!',
    },
    {
        id: 9,
        username: 'Gail & John',
        login: 'gailjohn',
        password: '!Palm_32_DaLe!',
    },
    {
        id: 10,
        username: 'Annamarie & Pat',
        login: 'annamariepat',
        password: '!Palm_111_dALe!',
    },
    {
        id: 11,
        username: 'Devi & Axel',
        login: 'devijustin',
        password: '!Palm_137_Cr0ft!',
    },
    {
        id: 12,
        username: 'Morton',
        login: 'morton',
        password: '!Palm_19_CR0fT!',
    },
    {
        id: 13,
        username: 'Kristen & Cody',
        login: 'kristencody',
        password: '!pAlM_115_Cr0fT!',
    },
    {
        id: 14,
        username: 'Gaspar',
        login: 'gaspar',
        password: '!pALm_115_DaL3!',
    },
    {
        id: 15,
        username: 'Elizabeth & Grant',
        login: 'elizabethgrant',
        password: '!PaLm_15_cR0fT!',
    },
    {
        id: 16,
        username: 'Rose & Dale',
        login: 'rosedale',
        password: '!dR0Me_2018_dARY!',
    },
    {
        id: 17,
        username: 'Nicki & Matt',
        login: 'nickimatt',
        password: '!PAlM_5_cR0fT!',
    },
    {
        id: 18,
        username: 'Molly & Harley',
        login: 'mollyharley',
        password: '!pALm_110_dAL3!',
    },
    {
        id: 19,
        username: 'Elvia',
        login: 'elvia',
        password: '$pALm_121_cRofT$',
    },
    {
        id: 20,
        username: 'Monica & Barry',
        login: 'monicabarry',
        password: '#PaLm_4_cRofT#',
    },
    {
        id: 21,
        username: 'Scott',
        login: 'scott',
        password: '#dRom3_2030_darY#',
    },
    {
        id: 22,
        username: 'Grace & Jeffery',
        login: 'gracejeffery',
        password: '$pALM_18_CrofT$',
    },
    {
        id: 23,
        username: 'Cathy & Karen',
        login: 'cathykaren',
        password: '$DaT3_2025_LaNd$',
    },
    {
        id: 24,
        username: 'Will & Mario',
        login: 'willmario',
        password: '$pALm_115_cr0FT$',
    },
];
