import React from 'react';
import { Link } from 'react-router-dom';

import { HouseWrapper, Address, House, TextWrapper, Owner, Phone, Email } from '../DefaultStyles';
import Arrow from '../components/Back';

export default function Home_25Palmcroft() {
    return (
        <HouseWrapper>
            <House src="/images/25palmcroft.jpg" alt="25 Palmcroft" />
            <TextWrapper>
                <Address>25 Palmcroft Dr.</Address>
                <Owner>Debbie Roberts</Owner>
                <Phone href="tel:"></Phone>
                <Email href="mailto:robertsteamaz@gmail.com">robertsteamaz@gmail.com</Email>
                <Owner>Kris Roberts</Owner>
                <Phone href="tel:"></Phone>
                <Email href="mailto:robertsteamaz@gmail.com">robertsteamaz@gmail.com</Email>
            </TextWrapper>
            <Link to="/map">
                <Arrow />
            </Link>
        </HouseWrapper>
    );
}
