import React from 'react';
import { Link } from 'react-router-dom';

import { HouseWrapper, Address, House, TextWrapper, Owner, Phone, Email } from '../DefaultStyles';
import Arrow from '../components/Back';

export default function Home_2018Dromedary() {
    return (
        <HouseWrapper>
            <House src="/images/2018dromedary.jpg" alt="2018 Dromedary" />
            <TextWrapper>
                <Address>2018 Dromedary Dr.</Address>
                <Owner>Rose Spier</Owner>
                <Phone href="tel:"></Phone>
                <Email href="mailto:"></Email>
                <Owner>Dale Spier</Owner>
                <Phone href="tel:480-745-0849">480-745-0849</Phone>
                <Email href="mailto:dalespier@msn.com">dalespier@msn.com</Email>
            </TextWrapper>
            <Link to="/map">
                <Arrow />
            </Link>
        </HouseWrapper>
    );
}
