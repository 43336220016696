import React from 'react';
import { Link } from 'react-router-dom';

import { HouseWrapper, Address, House, TextWrapper, Owner, Phone, Email } from '../DefaultStyles';
import Arrow from '../components/Back';

export default function Home_108Palmcroft() {
    return (
        <HouseWrapper>
            <House src="/images/108palmcroft.jpg" alt="1080 Palmcroft" />
            <TextWrapper>
                <Address>108 W. Palmcroft</Address>
                <Owner>Lisa Spahr</Owner>
                <Phone href="tel:520-488-8602">520-488-8602</Phone>
                <Email href="mailto:Spahrml@gmail.com">Spahrml@gmail.com</Email>
                <Owner>John Engen </Owner>
                <Phone href="tel:480-278-6833">480-278-6833</Phone>
                <Email href="mailto:John.engen@cox.net">John.engen@cox.net</Email>
                <ul>
                    Notes
                    <li>Tukee (the dog)</li>
                    <li>Not currently accepting calls or email</li>
                </ul>
            </TextWrapper>
            <Link to="/map">
                <Arrow />
            </Link>
        </HouseWrapper>
    );
}
