import React from 'react';
import { Link } from 'react-router-dom';

import { HouseWrapper, Address, House, TextWrapper, Owner, Phone, Email } from '../DefaultStyles';
import Arrow from '../components/Back';

export default function Home_5Palmcroft() {
    return (
        <HouseWrapper>
            <House src="/images/5palmcroft.jpg" alt="5 Palmcroft" />
            <TextWrapper>
                <Address>5 Palmcroft Dr.</Address>
                <Owner>Nicki Gammage</Owner>
                <Phone href="tel:602-301-3605">602-301-3605</Phone>
                <Email href="mailto:nicki.gammage@gmail.com">nicki.gammage@gmail.com</Email>
                <Owner>Matt Gammage</Owner>
                <Phone href="tel:602-326-8288">602-326-8288</Phone>
                <Email href="mailto:mattgammage@gmail.com">mattgammage@gmail.com</Email>
            </TextWrapper>
            <Link to="/map">
                <Arrow />
            </Link>
        </HouseWrapper>
    );
}
