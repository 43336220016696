import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import Arrow from '../components/Back';

export default function Map(props) {
    // https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
    // height: calc(var(--vh, 1vh) * 100);
    return (
        <>
            <Wrapper>
                <ImageMap src="/images/datepalmmap.png" />
                <Link5Palmcroft to="/5Palmcroft">
                    {' '}
                    <Number>5</Number>
                </Link5Palmcroft>
                <Link15Palmcroft to="/15Palmcroft">
                    {' '}
                    <Number>15</Number>
                </Link15Palmcroft>
                <Link19Palmcroft to="/19Palmcroft">
                    {' '}
                    <Number>19</Number>
                </Link19Palmcroft>
                <Link25Palmcroft to="/25Palmcroft">
                    {' '}
                    <Number>25</Number>
                </Link25Palmcroft>
                <Link103Palmcroft to="/103Palmcroft">
                    {' '}
                    <Number>103</Number>
                </Link103Palmcroft>
                <Link109Palmcroft to="/109Palmcroft">
                    {' '}
                    <Number>109</Number>
                </Link109Palmcroft>
                <Link115Palmcroft to="/115Palmcroft">
                    {' '}
                    <Number>115</Number>
                </Link115Palmcroft>
                <Link121Palmcroft to="/121Palmcroft">
                    {' '}
                    <Number>121</Number>
                </Link121Palmcroft>
                <Link133Palmcroft to="/133Palmcroft">
                    <Number>133</Number>
                </Link133Palmcroft>
                <Link137Palmcroft to="/137Palmcroft">
                    <Number>137</Number>
                </Link137Palmcroft>
                <Link2030Dromedary to="/2030Dromedary">
                    {' '}
                    <Number>2030</Number>
                </Link2030Dromedary>
                <Link2018Dromedary to="/2018Dromedary">
                    {' '}
                    <Number>2018</Number>
                </Link2018Dromedary>
                <Link120Palmdale to="/120Palmdale">
                    {' '}
                    <Number>120</Number>
                </Link120Palmdale>
                <Link114Palmdale to="/114Palmdale">
                    {' '}
                    <Number>114</Number>
                </Link114Palmdale>
                <Link110Palmdale to="/110Palmdale">
                    {' '}
                    <Number>110</Number>
                </Link110Palmdale>
                <Link100Palmdale to="/100Palmdale">
                    {' '}
                    <Number>100</Number>
                </Link100Palmdale>
                <Link44Palmdale to="/44Palmdale">
                    {' '}
                    <Number>44</Number>
                </Link44Palmdale>
                <Link36Palmdale to="/36Palmdale">
                    {' '}
                    <Number>36</Number>
                </Link36Palmdale>
                <Link32Palmdale to="/32Palmdale">
                    {' '}
                    <Number>32</Number>
                </Link32Palmdale>
                <Link2019Dateland to="/2019Dateland">
                    {' '}
                    <Number>2019</Number>
                </Link2019Dateland>
                <Link2025Dateland to="/2025Dateland">
                    {' '}
                    <Number>2025</Number>
                </Link2025Dateland>
                <Link26Palmcroft to="/26Palmcroft">
                    {' '}
                    <Number>26</Number>
                </Link26Palmcroft>
                <Link18Palmcroft to="/18Palmcroft">
                    {' '}
                    <Number>18</Number>
                </Link18Palmcroft>
                <Link14Palmcroft to="/14Palmcroft">
                    {' '}
                    <Number>14</Number>
                </Link14Palmcroft>
                <Link10Palmcroft to="/10Palmcroft">
                    {' '}
                    <Number>10</Number>
                </Link10Palmcroft>
                <Link102Palmcroft to="/102Palmcroft">
                    {' '}
                    <Number>102</Number>
                </Link102Palmcroft>
                <Link108Palmcroft to="/108Palmcroft">
                    {' '}
                    <Number>108</Number>
                </Link108Palmcroft>
                <Link116Palmcroft to="/116Palmcroft">
                    {' '}
                    <Number>116</Number>
                </Link116Palmcroft>
                <Link120Palmcroft to="/120Palmcroft">
                    {' '}
                    <Number>120</Number>
                </Link120Palmcroft>
                <Link130Palmcroft to="/130Palmcroft">
                    {' '}
                    <Number>130</Number>
                </Link130Palmcroft>
                <Link2015Dromedary to="/2015Dromedary">
                    {' '}
                    <Number>2015</Number>
                </Link2015Dromedary>
                <Link121Palmdale to="/121Palmdale">
                    {' '}
                    <Number>121</Number>
                </Link121Palmdale>
                <Link115Palmdale to="/115Palmdale">
                    {' '}
                    <Number>115</Number>
                </Link115Palmdale>
                <Link111Palmdale to="/111Palmdale">
                    {' '}
                    <Number>111</Number>
                </Link111Palmdale>
                <Link103Palmdale to="/103Palmdale">
                    {' '}
                    <Number>103</Number>
                </Link103Palmdale>
                <Link to="/home">
                    <Arrow />
                </Link>
            </Wrapper>
        </>
    );
}

const LinkStyles = css`
    background-color: white;
    position: absolute;
    display: grid;
    text-decoration: none;
    opacity: 0.7;
    &:hover {
        opacity: 0.3;
    }
`;

const Wrapper = styled.div`
    background-color: white;
    max-width: 1200px;
    overflow-x: auto;
    overflow-y: auto;
    position: relative;
    @media only screen and (min-width: 600px) {
        & > div {
            position: relative;
            max-width: 1200px;
            margin: 0 auto;
        }
    }
`;

const ImageMap = styled.img`
    width: 1200px;
    height: 700px;
`;

const Number = styled.span`
    display: inline-grid;
    justify-items: center;
    align-items: center;
    text-decoration: none;
    font-weight: 600;
    color: #444;
`;

const Link5Palmcroft = styled(Link)`
    ${LinkStyles}
    height: 49px;
    left: 1073px;
    top: 507px;
    width: 85px;
`;

const Link15Palmcroft = styled(Link)`
    ${LinkStyles}
    height: 43px;
    left: 942px;
    top: 531px;
    width: 79px;
`;

const Link19Palmcroft = styled(Link)`
    ${LinkStyles}
    height: 71px;
    left: 859px;
    top: 550px;
    width: 78px;
`;

const Link25Palmcroft = styled(Link)`
    ${LinkStyles}
    height: 55px;
    left: 725px;
    top: 569px;
    width: 132px;
`;

const Link103Palmcroft = styled(Link)`
    ${LinkStyles}
    height: 72px;
    left: 618px;
    top: 553px;
    width: 78px;
`;

const Link109Palmcroft = styled(Link)`
    ${LinkStyles}
    height: 59px;
    left: 535px;
    top: 557px;
    width: 78px;
`;

const Link115Palmcroft = styled(Link)`
    ${LinkStyles}
    height: 83px;
    left: 455px;
    top: 557px;
    width: 76px;
`;

const Link121Palmcroft = styled(Link)`
    ${LinkStyles}
    height: 56px;
    left: 329px;
    top: 557px;
    width: 118px;
`;

const Link133Palmcroft = styled(Link)`
    ${LinkStyles}
    height: 56px;
    left: 199px;
    top: 580px;
    width: 85px;
`;

const Link137Palmcroft = styled(Link)`
    ${LinkStyles}
    height: 81px;
    left: 97px;
    top: 522px;
    width: 85px;
`;

const Link2030Dromedary = styled(Link)`
    ${LinkStyles}
    height: 105px;
    left: 97px;
    top: 374px;
    width: 46px;
`;

const Link2018Dromedary = styled(Link)`
    ${LinkStyles}
    height: 96px;
    left: 91px;
    top: 262px;
    width: 52px;
`;

const Link120Palmdale = styled(Link)`
    ${LinkStyles}
    height: 99px;
    left: 91px;
    top: 99px;
    width: 101px;
`;

const Link114Palmdale = styled(Link)`
    ${LinkStyles}
    height: 84px;
    left: 192px;
    top: 70px;
    width: 101px;
`;

const Link110Palmdale = styled(Link)`
    ${LinkStyles}
    height: 60px;
    left: 299px;
    top: 93px;
    width: 66px;
`;

const Link100Palmdale = styled(Link)`
    ${LinkStyles}
    height: 49px;
    left: 387px;
    top: 109px;
    width: 76px;
`;

const Link44Palmdale = styled(Link)`
    ${LinkStyles}
    height: 71px;
    left: 527px;
    top: 82px;
    width: 82px;
`;

const Link36Palmdale = styled(Link)`
    ${LinkStyles}
    height: 38px;
    left: 626px;
    top: 96px;
    width: 84px;
`;

const Link32Palmdale = styled(Link)`
    ${LinkStyles}
    height: 83px;
    left: 747px;
    top: 102px;
    width: 68px;
`;

const Link2019Dateland = styled(Link)`
    ${LinkStyles}
    height: 82px;
    left: 765px;
    top: 216px;
    width: 70px;
`;

const Link2025Dateland = styled(Link)`
    ${LinkStyles}
    height: 71px;
    left: 744px;
    top: 306px;
    width: 70px;
}
`;

const Link26Palmcroft = styled(Link)`
    ${LinkStyles}
    height: 46px;
    left: 742px;
    top: 407px;
    width: 90px;
}
`;

const Link18Palmcroft = styled(Link)`
    ${LinkStyles}
    height: 95px;
    left: 861px;
    top: 337px;
    width: 77px;
}
`;

const Link14Palmcroft = styled(Link)`
    ${LinkStyles}
    height: 115px;
    left: 946px;
    top: 305px;
    width: 77px;
}
`;

const Link10Palmcroft = styled(Link)`
    ${LinkStyles}
    height: 48px;
    left: 1057px;
    top: 364px;
    width: 102px;
}
`;

const Link102Palmcroft = styled(Link)`
    ${LinkStyles}
    height: 51px;
    left: 581px;
    top: 401px;
    width: 83px;
}
`;

const Link108Palmcroft = styled(Link)`
    ${LinkStyles}
    height: 60px;
    left: 500px;
    top: 395px;
    width: 79px;
}
`;

const Link116Palmcroft = styled(Link)`
    ${LinkStyles}
    height: 55px;
    left: 410px;
    top: 401px;
    width: 82px;
}
`;

const Link120Palmcroft = styled(Link)`
    ${LinkStyles}
    height: 34px;
    left: 324px;
    top: 422px;
    width: 80px;
}
`;

const Link130Palmcroft = styled(Link)`
    ${LinkStyles}
    height: 81px;
    left: 251px;
    top: 378px;
    width: 64px;
}
`;

const Link2015Dromedary = styled(Link)`
    ${LinkStyles}
    height: 87px;
    left: 248px;
    top: 259px;
    width: 67px;
}
`;

const Link121Palmdale = styled(Link)`
    ${LinkStyles}
    height: 46px;
    left: 326px;
    top: 266px;
    width: 73px;
}
`;

const Link115Palmdale = styled(Link)`
    ${LinkStyles}
    height: 57px;
    left: 409px;
    top: 259px;
    width: 84px;
}
`;

const Link111Palmdale = styled(Link)`
    ${LinkStyles}
    height: 55px;
    left: 501px;
    top: 252px;
    width: 80px;
}
`;

const Link103Palmdale = styled(Link)`
    ${LinkStyles}
    height: 86px;
    left: 585px;
    top: 257px;
    width: 77px;
}
`;
