import React from 'react';
import { Link } from 'react-router-dom';

import { HouseWrapper, Address, House, TextWrapper, Owner, Phone, Email } from '../DefaultStyles';
import Arrow from '../components/Back';

export default function Home_115Palmdale() {
    return (
        <HouseWrapper>
            <House src="/images/115palmdale.jpg" alt="115 Palmdale" />
            <TextWrapper>
                <Address>115 Palmdale Dr.</Address>
                <Owner>Gaspar Tonnesen</Owner>
                <Phone href="tel:480-751-7157">480-751-7157</Phone>
                <Email href="mailto:gaspartonnesen@gmail.com ">gaspartonnesen@gmail.com </Email>
            </TextWrapper>
            <Link to="/map">
                <Arrow />
            </Link>
        </HouseWrapper>
    );
}
