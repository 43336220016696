import React from 'react';
import { Link } from 'react-router-dom';

import { HouseWrapper, Address, House, TextWrapper, Owner, Phone, Email } from '../DefaultStyles';
import Arrow from '../components/Back';

export default function Home_137Palmcroft() {
    return (
        <HouseWrapper>
            <House src="/images/137palmcroft.jpg" alt="137 Palmcroft Dr." />
            <TextWrapper>
                <Address>137 Palmcroft Dr.</Address>
                <Owner>Devi Strong</Owner>
                <Phone href="tel:480-326-5787">480-326-5787</Phone>
                <Email href="mailto:devistrong39@gmail.com">devistrong39@gmail.com</Email>
                <Owner>Justin (Axel) Carlson</Owner>
                <Phone href="tel:602-571-2327">602-571-2327</Phone>
                <Email href="mailto:partsy1@yahoo.com">partsy1@yahoo.com</Email>
            </TextWrapper>
            <Link to="/map">
                <Arrow />
            </Link>
        </HouseWrapper>
    );
}
