import React from 'react';
import { Link } from 'react-router-dom';

import { HouseWrapper, Address, House, TextWrapper, Owner, Phone, Email } from '../DefaultStyles';
import Arrow from '../components/Back';

export default function Home_115Palmcroft() {
    return (
        <HouseWrapper>
            <House src="/images/115palmcroft.jpg" alt="115 Palmcroft" />
            <TextWrapper>
                <Address>115 West Palmcroft Dr.</Address>
                <Owner>William Hart</Owner>
                <Phone href="tel:404-901-4970">404-901-4970</Phone>
                <Email href="mailto:whart1984@gmail.com">whart1984@gmail.com</Email>
                <Owner>Dr. Mario Pineda</Owner>
                <Phone href="tel:206-931-2401">206-931-2401</Phone>
                <Email href="mailto:whart1984@gmail.com">whart1984@gmail.com</Email>
            </TextWrapper>
            <Link to="/map">
                <Arrow />
            </Link>
        </HouseWrapper>
    );
}
