import React from 'react';
import { Link } from 'react-router-dom';

import { HouseWrapper, Address, House, TextWrapper, Owner, Phone, Email } from '../DefaultStyles';
import Arrow from '../components/Back';

export default function Home_121Palmcroft() {
    return (
        <HouseWrapper>
            <House src="/images/121palmcroft.jpg" alt="121 Palmcroft" />
            <TextWrapper>
                <Address>121 Palmcroft Dr.</Address>
                <Owner>Elvia Gomez</Owner>
                <Phone href="tel:480-628-6745">480-628-6745</Phone>
                <Email href="mailto:egomezd@hotmail.com">egomezd@hotmail.com</Email>
            </TextWrapper>
            <Link to="/map">
                <Arrow />
            </Link>
        </HouseWrapper>
    );
}
