import React from 'react';
import { Link } from 'react-router-dom';

import { HouseWrapper, Address, House, TextWrapper, Owner, Phone, Email } from '../DefaultStyles';
import Arrow from '../components/Back';

export default function Home_2030Dromedary() {
    return (
        <HouseWrapper>
            <House src="/images/2030dromedary.jpg" alt="2030 Dromedary" />
            <TextWrapper>
                <Address>2030 Dromedary Dr.</Address>
                <Owner>Scott Bills</Owner>
                <Phone href="tel:480-432-2070">480-432-2070</Phone>
                <Email href="mailto:Scott_bills@msn.com">Scott_bills@msn.com</Email>
            </TextWrapper>
            <Link to="/map">
                <Arrow />
            </Link>
        </HouseWrapper>
    );
}
