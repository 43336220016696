import React from 'react';
import { Link } from 'react-router-dom';

import { HouseWrapper, Address, House, TextWrapper, Owner, Phone, Email } from '../DefaultStyles';
import Arrow from '../components/Back';

export default function Home_10Palmcroft() {
    return (
        <HouseWrapper>
            <House src="/images/10palmcroft.jpg" alt="10 Palmcroft" />
            <TextWrapper>
                <Address>10 Palmcroft Dr.</Address>
                <Owner>xxxx</Owner>
                <Phone href="tel:">xxxx</Phone>
                <Email href="mailto:">xxxx</Email>
                <Owner>xxxx</Owner>
                <Phone href="tel:">xxxx</Phone>
                <Email href="mailto:">xxxx</Email>
            </TextWrapper>
            <Link to="/map">
                <Arrow />
            </Link>
        </HouseWrapper>
    );
}
