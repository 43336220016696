import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';

document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
function setDocHeight() {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
}

window.addEventListener('resize', function () {
    setDocHeight();
});
window.addEventListener('orientationchange', function () {
    setDocHeight();
});

setDocHeight();

export const GlobalStyle = createGlobalStyle`



* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;

}

body {
  margin: 0;
  background-color: white;
}

:root {
    --light-purple: gold;
}
`;

export const Address = styled.h1`
    font-size: 29px;
    font-weight: 200;
    letter-spacing: -1px;
    line-height: 34px;
    margin-bottom: 40px;
`;

export const House = styled.img`
    width: 100%;
`;

export const TextWrapper = styled.div`
    overflow: auto;
    padding: 20px 20px 75px 20px;
    ul {
        clear: both;
        font-weight: 700;
        li {
            margin-left: 30px;
            font-weight: 400;
        }
    }
`;

export const Owner = styled.h2`
    clear: both;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
`;

export const Phone = styled.a`
    background-color: #eeeeee;
    clear: both;
    color: #555;
    display: inline-block;
    float: left;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin: 10px 0;
    padding: 8px 18px;
    text-decoration: none;
    transition: 0.5s;
    &:before {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: '\f87b';
        padding-right: 5px;
    }
    &:hover {
        background-color: #c4c4c4;
    }
`;

export const Email = styled.a`
    background-color: #eeeeee;
    color: #555;
    display: inline-block;
    clear: both;
    float: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 30px;
    padding: 8px 18px;
    text-decoration: none;
    transition: 0.5s;
    &:before {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: '\f0e0';
        padding-right: 5px;
    }
    &:hover {
        background-color: #c4c4c4;
    }
`;

export const HouseWrapper = styled.div`
    position: relative;
    max-width: 600px !important;
    @media only screen and (min-width: 600px) {
        margin: 0 auto;
    }
`;
