import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export default function Login({ handleLogin, setLogin, setPassword }) {
    return (
        <FormWrapper>
            <form onSubmit={handleLogin}>
                <div>
                    <p>User name</p>
                    <input
                        name="loginName"
                        type="text"
                        placeholder="Enter User name"
                        onChange={(e) => setLogin(e.target.value)}
                    />
                </div>
                <div>
                    <p>Password</p>
                    <input
                        name="password"
                        type="password"
                        placeholder="Enter password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div>
                    <Submit type="submit" value="Submit" />
                </div>
                {/* ERROR IF INVALID LOGIN */}
                <div id="error" />
            </form>
        </FormWrapper>
    );
}

Login.propTypes = {
    handleLogin: PropTypes.any,
    setEmail: PropTypes.any,
    setPassword: PropTypes.any,
};

// styles

const FormWrapper = styled.div`
    margin: 75px auto 0 auto;
    max-width: 400px;
    position: relative;
    width: 90%;

    div {
        padding: 10px;
    }

    p {
        margin-top: 0px;
        padding-top: 0px;
    }

    label {
        padding: 3px;
    }
    input {
        font-size: 16px;
        padding: 15px;
        width: 100%;
    }
`;

const Submit = styled.input`
    background-color: maroon;
    border-radius: 50px;
    border: 0;
    color: white;
    font-weight: 600;
    letter-spacing: 1px;
    margin-top: 10px;
    padding: 20px !important;
    text-transform: uppercase;
    width: 200px !important;
    &:hover {
        cursor: pointer;
    }
`;
