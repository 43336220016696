import React from 'react';
import { Link } from 'react-router-dom';

import { HouseWrapper, Address, House, TextWrapper, Owner, Phone, Email } from '../DefaultStyles';
import Arrow from '../components/Back';

export default function Home_111Palmdale() {
    return (
        <HouseWrapper>
            <House src="/images/111palmdale.jpg" alt="111 Palmdale" />
            <TextWrapper>
                <Address>111 Palmdale Dr.</Address>
                <Owner>Annamarie Oliverio Lauderdale</Owner>
                <Phone href="tel:480-429-2099">480-429-2099</Phone>
                <Email href="mailto:annamarie@asu.edu">annamarie@asu.edu</Email>
                <Owner>Pat Lauderdale</Owner>
                <Phone href="tel:480-326-8486">480-326-8486</Phone>
                <Email href="mailto:pat.lauderdale@asu.edu">pat.lauderdale@asu.edu</Email>
            </TextWrapper>
            <Link to="/map">
                <Arrow />
            </Link>
        </HouseWrapper>
    );
}
