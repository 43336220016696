import React from 'react';
import { Link } from 'react-router-dom';

import { HouseWrapper, Address, House, TextWrapper, Owner, Phone, Email } from '../DefaultStyles';
import Arrow from '../components/Back';

export default function Home_120Palmdale() {
    return (
        <HouseWrapper>
            <House src="/images/120palmdale.jpg" alt="120 Palmdale" />
            <TextWrapper>
                <Address>120 Palmdale Dr.</Address>
                <Owner>xxxx</Owner>
                <Phone href="tel:">xxxx</Phone>
                <Email href="mailto:">xxxx</Email>
                <Owner>xxxx</Owner>
                <Phone href="tel:">xxxx</Phone>
                <Email href="mailto:">xxxx</Email>
            </TextWrapper>
            <Link to="/map">
                <Arrow />
            </Link>
        </HouseWrapper>
    );
}
