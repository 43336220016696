import React from 'react';
import { Link } from 'react-router-dom';

import { HouseWrapper, Address, House, TextWrapper, Owner, Phone, Email } from '../DefaultStyles';
import Arrow from '../components/Back';

export default function Home_2015Dromedary() {
    return (
        <HouseWrapper>
            <House src="/images/2015dromedary.jpg" alt="2015 Dromedary Dr." />
            <TextWrapper>
                <Address>2015 Dromedary Dr.</Address>
                <Owner>Deborah Sussman</Owner>
                <Phone href="tel:480-818-3624">480-818-3624</Phone>
                <Email href="mailto:deborahhsussman@gmail.com">deborahhsussman@gmail.com</Email>
                <Owner>Neil Borowicz</Owner>
                <Phone href="tel:602-619-0744">602-619-0744</Phone>
                <Email href="mailto:neil.borowicz@gmail.com">neil.borowicz@gmail.com</Email>
            </TextWrapper>
            <Link to="/map">
                <Arrow />
            </Link>
        </HouseWrapper>
    );
}
