import React from 'react';
import { Link } from 'react-router-dom';

import { HouseWrapper, Address, House, TextWrapper, Owner, Phone, Email } from '../DefaultStyles';
import Arrow from '../components/Back';

export default function Home_2025Dateland() {
    return (
        <HouseWrapper>
            <House src="/images/2025dateland.jpg" alt="2025 Dateland" />
            <TextWrapper>
                <Address>2025 Dateland Dr.</Address>
                <Owner>Cathy Bailie</Owner>
                <Phone href="tel:480-350-9049">480-350-9049</Phone>
                <Email href="mailto:cjbaillie@cox.net">cjbaillie@cox.net</Email>
                <Owner>Karen Bailie</Owner>
                <Phone href="tel:480-350-9049">480-350-9049</Phone>
                <Email href="mailto:cjbaillie@cox.net">cjbaillie@cox.net</Email>
            </TextWrapper>
            <Link to="/map">
                <Arrow />
            </Link>
        </HouseWrapper>
    );
}
