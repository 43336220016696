import React from 'react';
import { Link } from 'react-router-dom';

import { HouseWrapper, Address, House, TextWrapper, Owner, Phone, Email } from '../DefaultStyles';
import Arrow from '../components/Back';

export default function Home_25Palmcroft() {
    return (
        <HouseWrapper>
            <House src="/images/19palmcroft.jpg" alt="19 Palmcroft" />
            <TextWrapper>
                <Address>19 Palmcroft Dr.</Address>
                <Owner>Morton Monk</Owner>
                <Phone href="tel:480-330-6667">480-330-6667</Phone>
                <Email href="mailto:mortm@cox.net">mortm@cox.net</Email>
            </TextWrapper>
            <Link to="/map">
                <Arrow />
            </Link>
        </HouseWrapper>
    );
}
