import styled from 'styled-components';

import Routing from './pages/Routes';

function App() {
    return (
        <Wrapper>
            <Routing />
        </Wrapper>
    );
}

export default App;

const Wrapper = styled.div`
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    & > div {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
`;
