import React from 'react';
import { Link } from 'react-router-dom';

import { HouseWrapper, Address, House, TextWrapper, Owner, Phone, Email } from '../DefaultStyles';
import Arrow from '../components/Back';

export default function Home_36Palmdale() {
    return (
        <HouseWrapper>
            <House src="/images/36palmdale.jpg" alt="36 Palmdale" />
            <TextWrapper>
                <Address>36 Palmdale Dr.</Address>
                <Owner>Daphne Gracey</Owner>
                <Phone href="tel:480-250-5595">480-250-5595</Phone>
                <Email href="mailto:daphnegracey@gmail.com">daphnegracey@gmail.com</Email>
                <Owner>Dave Gracey</Owner>
                <Phone href="tel:602-418-8078">602-418-8078</Phone>
                <Email href="mailto:acegracey@aol.com">acegracey@aol.com</Email>
            </TextWrapper>
            <Link to="/map">
                <Arrow />
            </Link>
        </HouseWrapper>
    );
}
