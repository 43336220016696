import React from 'react';
import { Link } from 'react-router-dom';

import { HouseWrapper, Address, House, TextWrapper, Owner, Phone, Email } from '../DefaultStyles';
import Arrow from '../components/Back';

export default function Home_15Palmcroft() {
    return (
        <HouseWrapper>
            <House src="/images/15palmcroft.jpg" alt="15 Palmcroft" />
            <TextWrapper>
                <Address>15 Palmcroft Dr.</Address>
                <Owner>Elizabeth Crane</Owner>
                <Phone href="tel:480-274-3535">480-274-3535</Phone>
                <Email href="mailto:Elizabethcranern@gmail.com">Elizabethcranern@gmail.com</Email>
                <Owner>Grant Holburn</Owner>
                <Phone href="tel:480-748-7288">480-748-7288</Phone>
                <Email href="mailto:Gholburn@gmail.com">Gholburn@gmail.com</Email>
            </TextWrapper>
            <Link to="/map">
                <Arrow />
            </Link>
        </HouseWrapper>
    );
}
