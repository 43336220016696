import React from 'react';
import { Link } from 'react-router-dom';

import { HouseWrapper, Address, House, TextWrapper, Owner, Phone, Email } from '../DefaultStyles';
import Arrow from '../components/Back';

export default function Home_114Palmdale() {
    return (
        <HouseWrapper>
            <House src="/images/114palmdale.jpg" alt="114 Palmdale" />
            <TextWrapper>
                <Address>114 Palmdale Dr.</Address>
                <Owner>Janeen Rohovit</Owner>
                <Phone href="tel:480-603-7230">480-603-7230</Phone>
                <Email href="mailto:azjaneen@yahoo.com">azjaneen@yahoo.com</Email>
            </TextWrapper>
            <Link to="/map">
                <Arrow />
            </Link>
        </HouseWrapper>
    );
}
