import React from 'react';
import { Link } from 'react-router-dom';

import { HouseWrapper, Address, House, TextWrapper, Owner, Phone, Email } from '../DefaultStyles';
import Arrow from '../components/Back';

export default function Home_18Palmcroft() {
    return (
        <HouseWrapper>
            <House src="/images/18palmcroft.jpg" alt="18 Palmcroft" />
            <TextWrapper>
                <Address>18 Palmcroft Dr.</Address>
                <Owner>Grace Liu</Owner>
                <Phone href="tel:"></Phone>
                <Email href="mailto:Lucky3339@gmail.com">Lucky3339@gmail.com</Email>
                <Owner>Jeffery Shepard</Owner>
                <Phone href="tel:602-743-9536">602-743-9536</Phone>
                <Email href="mailto:jeff012565shepard@msn.com">jeff012565shepard@msn.com</Email>
            </TextWrapper>
            <Link to="/map">
                <Arrow />
            </Link>
        </HouseWrapper>
    );
}
