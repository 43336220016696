import React from 'react';
import { Link } from 'react-router-dom';

import { HouseWrapper, Address, House, TextWrapper, Owner, Phone, Email } from '../DefaultStyles';
import Arrow from '../components/Back';

export default function Home_110Palmdale() {
    return (
        <HouseWrapper>
            <House src="/images/110palmdale.jpg" alt="110 Palmdale" />
            <TextWrapper>
                <Address>110 Palmdale Dr.</Address>
                <Owner>Molly Vrizuela</Owner>
                <Phone href="tel:480-363-2876">480-363-2876</Phone>
                <Email href="mailto:mollyv31@gmail.com">mollyv31@gmail.com</Email>
                <Owner>Harley Swartz</Owner>
                <Phone href="tel:480-371-7505">480-371-7505</Phone>
                <Email href="mailto:owlboy@cox.net">owlboy@cox.net</Email>
            </TextWrapper>
            <Link to="/map">
                <Arrow />
            </Link>
        </HouseWrapper>
    );
}
