import React from 'react';
import { Link } from 'react-router-dom';

import { HouseWrapper, Address, House, TextWrapper, Owner, Phone, Email } from '../DefaultStyles';
import Arrow from '../components/Back';

export default function Home_14Palmcroft() {
    return (
        <HouseWrapper>
            <House src="/images/14palmcroft.jpg" alt="14 Palmcroft" />
            <TextWrapper>
                <Address>14 Palmcroft Dr.</Address>
                <Owner>Monica Gaughan</Owner>
                <Phone href="tel:480-636-0120">480-636-0120</Phone>
                <Email href="mailto:monica.gaughan@gmail.com">monica.gaughan@gmail.com</Email>
                <Owner>Barry Bozeman</Owner>
                <Phone href="tel:480-636-0120">480-636-0120</Phone>
                <Email href="mailto:barry.bozeman@gmail.com">barry.bozeman@gmail.com</Email>
            </TextWrapper>
            <Link to="/map">
                <Arrow />
            </Link>
        </HouseWrapper>
    );
}
