import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './Home';
import Map from './Map';
import Home_5Palmcroft from './Home_5Palmcroft';
import Home_10Palmcroft from './Home_10Palmcroft';
import Home_14Palmcroft from './Home_14Palmcroft';
import Home_15Palmcroft from './Home_15Palmcroft';
import Home_18Palmcroft from './Home_18Palmcroft';
import Home_19Palmcroft from './Home_19Palmcroft';
import Home_25Palmcroft from './Home_25Palmcroft';
import Home_26Palmcroft from './Home_26Palmcroft';
import Home_102Palmcroft from './Home_102Palmcroft';
import Home_103Palmcroft from './Home_103Palmcroft';
import Home_108Palmcroft from './Home_108Palmcroft';
import Home_109Palmcroft from './Home_109Palmcroft';
import Home_115Palmcroft from './Home_115Palmcroft';
import Home_116Palmcroft from './Home_116Palmcroft';
import Home_120Palmcroft from './Home_120Palmcroft';
import Home_121Palmcroft from './Home_121Palmcroft';
import Home_130Palmcroft from './Home_130Palmcroft';
import Home_133Palmcroft from './Home_133Palmcroft';
import Home_137Palmcroft from './Home_137Palmcroft';
import Home_2015Dromedary from './Home_2015Dromedary';
import Home_2018Dromedary from './Home_2018Dromedary';
import Home_2030Dromedary from './Home_2030Dromedary';
import Home_32Palmdale from './Home_32Palmdale';
import Home_36Palmdale from './Home_36Palmdale';
import Home_44Palmdale from './Home_44Palmdale';
import Home_100Palmdale from './Home_100Palmdale';
import Home_103Palmdale from './Home_103Palmdale';
import Home_110Palmdale from './Home_110Palmdale';
import Home_111Palmdale from './Home_111Palmdale';
import Home_114Palmdale from './Home_114Palmdale';
import Home_115Palmdale from './Home_115Palmdale';
import Home_120Palmdale from './Home_120Palmdale';
import Home_121Palmdale from './Home_121Palmdale';
import Home_2019Dateland from './Home_2019Dateland';
import Home_2025Dateland from './Home_2025Dateland';

export default function Routes() {
    return (
        <>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/home" component={Home} />
                <Route exact path="/map" component={Map} />
                <Route exact path="/5Palmcroft" component={Home_5Palmcroft} />
                <Route exact path="/10Palmcroft" component={Home_10Palmcroft} />
                <Route exact path="/14Palmcroft" component={Home_14Palmcroft} />
                <Route exact path="/15Palmcroft" component={Home_15Palmcroft} />
                <Route exact path="/18Palmcroft" component={Home_18Palmcroft} />
                <Route exact path="/19Palmcroft" component={Home_19Palmcroft} />
                <Route exact path="/25Palmcroft" component={Home_25Palmcroft} />
                <Route exact path="/26Palmcroft" component={Home_26Palmcroft} />
                <Route exact path="/102Palmcroft" component={Home_102Palmcroft} />
                <Route exact path="/103Palmcroft" component={Home_103Palmcroft} />
                <Route exact path="/108Palmcroft" component={Home_108Palmcroft} />
                <Route exact path="/109Palmcroft" component={Home_109Palmcroft} />
                <Route exact path="/115Palmcroft" component={Home_115Palmcroft} />
                <Route exact path="/116Palmcroft" component={Home_116Palmcroft} />
                <Route exact path="/120Palmcroft" component={Home_120Palmcroft} />
                <Route exact path="/121Palmcroft" component={Home_121Palmcroft} />
                <Route exact path="/130Palmcroft" component={Home_130Palmcroft} />
                <Route exact path="/133Palmcroft" component={Home_133Palmcroft} />
                <Route exact path="/137Palmcroft" component={Home_137Palmcroft} />
                <Route exact path="/2015Dromedary" component={Home_2015Dromedary} />
                <Route exact path="/2018Dromedary" component={Home_2018Dromedary} />
                <Route exact path="/2030Dromedary" component={Home_2030Dromedary} />
                <Route exact path="/32Palmdale" component={Home_32Palmdale} />
                <Route exact path="/36Palmdale" component={Home_36Palmdale} />
                <Route exact path="/44Palmdale" component={Home_44Palmdale} />
                <Route exact path="/100Palmdale" component={Home_100Palmdale} />
                <Route exact path="/103Palmdale" component={Home_103Palmdale} />
                <Route exact path="/110Palmdale" component={Home_110Palmdale} />
                <Route exact path="/111Palmdale" component={Home_111Palmdale} />
                <Route exact path="/114Palmdale" component={Home_114Palmdale} />
                <Route exact path="/115Palmdale" component={Home_115Palmdale} />
                <Route exact path="/120Palmdale" component={Home_120Palmdale} />
                <Route exact path="/121Palmdale" component={Home_121Palmdale} />
                <Route exact path="/2019Dateland" component={Home_2019Dateland} />
                <Route exact path="/2025Dateland" component={Home_2025Dateland} />
            </Switch>
        </>
    );
}
