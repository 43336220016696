import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// import { Link } from 'react-router-dom';

// LOCAL IMPORTS
import { users } from '../userList.js';
import Welcome from '../components/Welcome';
import Login from '../components/Login';
import Header from '../components/Header';

export default function Home(props) {
    // https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
    // height: calc(var(--vh, 1vh) * 100);

    const initialUser = () => window.localStorage.getItem('palmcroftuser') || '';
    const [palmcroftuser, setPalmcroftuser] = useState(initialUser);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        window.localStorage.setItem('palmcroftuser', palmcroftuser);
    }, [palmcroftuser]);

    const handleLogin = (e) => {
        e.preventDefault();

        // SEE IF HANDLED USER & PASSWORD ARE IN FAMILY LIST

        const filteredUsers = users.filter(function (fam) {
            return fam.login === login && fam.password === password;
        });

        // IF FILTEREDUSERS ARRAY EXISTS ("[0]"), SET LOCAL STORAGE

        if (filteredUsers[0]) {
            setPalmcroftuser(filteredUsers[0].username);
        } else {
            document.getElementById('error').innerHTML = 'Invalid Login';
        }
    };

    // LOGOUT: SET ITEMS TO BLANK STRINGS, RELOAD PAGE.

    const handleLogout = (e) => {
        e.preventDefault();
        window.localStorage.setItem('palmcroftuser', '');
        setTimeout(function () {
            window.location.reload(true);
        }, 100);
    };

    return (
        <Wrapper>
            <Header name={palmcroftuser} handleLogout={handleLogout} />
            {/* IS USER VALUE IN LOCAL STORAGE? */}
            {palmcroftuser ? (
                // IF SO, SHOW TABBED CONTENT
                <Welcome name={palmcroftuser} />
            ) : (
                // IF NOT, THEY GET A LOGIN FORM
                <Login handleLogin={handleLogin} setLogin={setLogin} setPassword={setPassword} />
            )}
        </Wrapper>
        // <Wrapper>
        //     <h1>Welcome!</h1>
        //     <Link to="/map">Map</Link>
        // </Wrapper>
    );
}

const Wrapper = styled.div`
    background-image: url('/images/wall-phone.jpg');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    @media screen and (min-width: 700px) {
        background-image: url('/images/wall-desktop.jpg');
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
    }
`;
