import React from 'react';
import { Link } from 'react-router-dom';

import { HouseWrapper, Address, House, TextWrapper, Owner, Phone, Email } from '../DefaultStyles';
import Arrow from '../components/Back';

export default function Home_103Palmcroft() {
    return (
        <HouseWrapper>
            <House src="/images/103palmcroft.jpg" alt="103 Palmcroft" />
            <TextWrapper>
                <Address>103 Palmcroft Dr.</Address>
                <Owner>Sarah Rose</Owner>
                <Phone href="tel:415-602-3193">415-602-3193</Phone>
                <Email href="mailto:SCLRose@gmail.com">SCLRose@gmail.com</Email>
                <Owner>Larry Rose</Owner>
                <Phone href="tel:415-279-0367">415-279-0367</Phone>
                <Email href="mailto:LawrenceMRose@gmail.com">LawrenceMRose@gmail.com</Email>
            </TextWrapper>
            <Link to="/map">
                <Arrow />
            </Link>
        </HouseWrapper>
    );
}
