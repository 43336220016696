import React from 'react';
import styled from 'styled-components';

export default function Back() {
    return <Arrow src="/images/back.png" alt="back arrow" />;
}
const Arrow = styled.img`
    bottom: 0;
    height: auto;
    position: fixed;
    right: 0;
    transition: 0.3s;
    width: 120px;
    &:hover {
        opacity: 0.6;
    }
`;
