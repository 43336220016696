import React from 'react';
import { Link } from 'react-router-dom';

import { HouseWrapper, Address, House, TextWrapper, Owner, Phone, Email } from '../DefaultStyles';
import Arrow from '../components/Back';

export default function Home_133Palmcroft() {
    return (
        <HouseWrapper>
            <House src="/images/133palmcroft.jpg" alt="133 Palmcroft" />
            <TextWrapper>
                <Address>133 Palmcroft Dr.</Address>
                <Owner>Penny Walker</Owner>
                <Phone href="tel:602-909-9076">602-909-9076</Phone>
                <Email href="mailto:walkerpliske@gmail.com">walkerpliske@gmail.com</Email>
                <Owner>Bill Pliske</Owner>
                <Phone href="tel:623-707-9987">623-707-9987</Phone>
                <Email href="mailto:billpliske@gmail.com:">billpliske@gmail.com</Email>
            </TextWrapper>
            <Link to="/map">
                <Arrow />
            </Link>
        </HouseWrapper>
    );
}
